import { HashRouter, Routes, Route, Link } from "react-router-dom";
import './App.css';

import Home from "./pages/Home";
import Gov from "./pages/Gov";


function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/gov" element={<Gov />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </HashRouter>
    </>
  )
}

export default App;

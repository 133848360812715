import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpBackend from "i18next-http-backend";

// 初始化 i18next
i18n
    .use(HttpBackend) // 使用 Http 后端加载翻译文件
    .use(LanguageDetector) // 自动检测用户语言
    .use(initReactI18next) // 绑定 React
    .init({
        fallbackLng: "en", // 默认语言
        debug: true, // 开启调试模式
        interpolation: {
            escapeValue: false, // React 已经对 XSS 进行防护
        },
        backend: {
            loadPath: "/locales/{{lng}}/{{ns}}.json", // 翻译文件路径
        },
        react: {
            useSuspense: false, // 如果不使用 React 18 的 Suspense
        },
    });

export default i18n;

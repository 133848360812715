import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';

import "./i18n";

import '@rainbow-me/rainbowkit/styles.css';
import {
  getDefaultConfig,
  RainbowKitProvider,
  // darkTheme,
  lightTheme
  // darkTheme
  // midnightTheme
} from '@rainbow-me/rainbowkit';
import { WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  confluxESpace,
  //  confluxESpaceTestnet,
  zksync,
  sepolia
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";



const config = getDefaultConfig({
  appName: 'My dApp',
  projectId: '3fbb6bba6f1de962d911bb5b5c9dba88',
  chains: [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    {
      ...confluxESpace,
      iconBackground: '#000',
      iconUrl: 'https://example.com/icons/ethereum.png',
    },
    // confluxESpaceTestnet,
    zksync,
    sepolia
  ],
  ssr: true, // If your dApp uses server side rendering (SSR)
  // transports: {
  //   [mainnet.id]: http('https://eth-mainnet.g.alchemy.com/v2/...'),
  //   [sepolia.id]: http('https://eth-sepolia.g.alchemy.com/v2/...'),
  // },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider
            modalSize="compact"
            theme={lightTheme({
              fontStack: 'system',
              overlayBlur: 'small',
            })}
            // locale="zh-CN"
            locale="en-US"
            // locale="ar-AR"
            showRecentTransactions={true}
          >
            {/* Your App */}
            <App />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


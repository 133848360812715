import { useState } from 'react'
// import { useParams, Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import bg from 'images/BG.gif'
import logo from 'images/logo.svg'
import logox from 'images/logox.svg'
import logogit from 'images/logogit.svg'
import logomedium from 'images/logomedium.svg'
import logodis from 'images/logodis.svg'
import logotg from 'images/logotg.svg'
import logofar from 'images/logofar.svg'
import pice from 'images/pice.svg'
import h5menu from 'images/h5menu.svg'
import h5menuClose from 'images/h5menuClose.svg'
import govLine from 'images/govLine.svg'
import arrowright from 'images/arrowright.svg'

function Page() {
    const [showMenu, setShowMenu] = useState(false)




    return (
        <>
            {
                showMenu &&
                <ModalMenu>
                    <BackWrap
                        onClick={() => { setShowMenu(false) }}
                    >
                        <MenuWrapH5 className='reverse'>
                            {/* <img src={logo} alt="" className="logo" /> */}
                            <img
                                onClick={() => { setShowMenu(false) }}
                                src={h5menuClose} alt="" className="menu" />
                        </MenuWrapH5>

                        <H5Title>Product</H5Title>
                        <H5ListWrap>
                            <H5List>
                                <MenuList href="#" target={'_blank'}>
                                    AI Agent Framework
                                </MenuList>
                                <MenuList href="https://app.unitus.finance" target={'_blank'}>
                                    Unitus
                                </MenuList>
                                <MenuList href="https://app.usx.finance/" target={'_blank'}>
                                    USX
                                </MenuList>
                                <MenuList href="https://bridge.dforce.network/" target={'_blank'}>
                                    Bridge
                                </MenuList>
                                <MenuList href="https://app.dforce.network/" target={'_blank'}>
                                    Legacy
                                </MenuList>
                            </H5List>
                        </H5ListWrap>

                        <H5Title>Doc</H5Title>
                        <H5ListWrap>
                            <H5List>
                                <MenuList href="https://docs.dforce.network/" target={'_blank'}>
                                    GitBook
                                </MenuList>
                                <MenuList href="https://github.com/dforce-network" target={'_blank'}>
                                    GitHub
                                </MenuList>
                            </H5List>
                        </H5ListWrap>

                        <H5Title>Community</H5Title>
                        <H5ListWrap>
                            <H5List>
                                <MenuList href="https://x.com/dForcenet" target={'_blank'}>
                                    X
                                </MenuList>
                                <MenuList href="https://warpcast.com/dforce" target={'_blank'}>
                                    Farcaster
                                </MenuList>
                                <MenuList href="https://t.me/dforcenet" target={'_blank'}>
                                    Telegram
                                </MenuList>
                                <MenuList href="https://discord.com/invite/c2PC8SM" target={'_blank'}>
                                    Discord
                                </MenuList>
                                <MenuList href="https://medium.com/dforcenet" target={'_blank'}>
                                    Medium
                                </MenuList>
                            </H5List>
                        </H5ListWrap>

                        <H5Title>Governance</H5Title>
                        <H5ListWrap>
                            <H5List>
                                <MenuList href="https://forum.dforce.network/" target={'_blank'}>
                                    Forum
                                </MenuList>
                                <MenuList href="https://snapshot.org/#/s:dforcenet.eth" target={'_blank'}>
                                    Snapshot
                                </MenuList>
                                <MenuList href="https://gov.dforce.network/#/StakeDF" target={'_blank'}>
                                    Staking
                                </MenuList>
                            </H5List>
                        </H5ListWrap>
                    </BackWrap>
                </ModalMenu>
            }

            <TopWrap>
                {/* pc menu */}
                <MenuWrap>
                    <MenuLeft>
                        <img src={logo} alt="" />
                    </MenuLeft>
                    <MenuRight>
                        <MenuItem>
                            Product
                            <ListWrap>
                                <MenuList href="#" target={'_blank'}>
                                    AI Agent Framework
                                </MenuList>
                                <MenuList href="https://app.unitus.finance" target={'_blank'}>
                                    Unitus
                                </MenuList>
                                <MenuList href="https://app.usx.finance/" target={'_blank'}>
                                    USX
                                </MenuList>
                                <MenuList href="https://bridge.dforce.network/" target={'_blank'}>
                                    Bridge
                                </MenuList>
                                <MenuList href="https://app.dforce.network/" target={'_blank'}>
                                    Legacy
                                </MenuList>
                            </ListWrap>
                        </MenuItem>
                        <MenuItem>
                            Doc
                            <ListWrap>
                                <MenuList href="https://docs.dforce.network/" target={'_blank'}>
                                    GitBook
                                </MenuList>
                                <MenuList href="https://github.com/dforce-network" target={'_blank'}>
                                    GitHub
                                </MenuList>
                            </ListWrap>
                        </MenuItem>
                        <MenuItem>
                            Community
                            <ListWrap>
                                <MenuList href="https://x.com/dForcenet" target={'_blank'}>
                                    X
                                </MenuList>
                                <MenuList href="https://warpcast.com/dforce" target={'_blank'}>
                                    Farcaster
                                </MenuList>
                                <MenuList href="https://t.me/dforcenet" target={'_blank'}>
                                    Telegram
                                </MenuList>
                                <MenuList href="https://discord.com/invite/c2PC8SM" target={'_blank'}>
                                    Discord
                                </MenuList>
                                <MenuList href="https://medium.com/dforcenet" target={'_blank'}>
                                    Medium
                                </MenuList>
                            </ListWrap>
                        </MenuItem>
                        <MenuItem>
                            Governance
                            <ListWrap>
                                <MenuList href="https://forum.dforce.network/" target={'_blank'}>
                                    Forum
                                </MenuList>
                                <MenuList href="https://snapshot.org/#/s:dforcenet.eth" target={'_blank'}>
                                    Snapshot
                                </MenuList>
                                <MenuList href="https://gov.dforce.network/#/StakeDF" target={'_blank'}>
                                    Staking
                                </MenuList>
                            </ListWrap>
                        </MenuItem>
                    </MenuRight>
                </MenuWrap>
                {/* h5 menu */}
                <MenuWrapH5>
                    <img src={logo} alt="" className="logo" />
                    <img
                        onClick={() => { setShowMenu(true) }}
                        src={h5menu} alt="" className="menu" />
                </MenuWrapH5>

                <FilterWrap></FilterWrap>

                <MainTitle>dForce Governance</MainTitle>
                <SubTitle>dForce DAO ensures long-term success by making decentralization a guiding priority in governance.</SubTitle>

                <LineWrapPositionGov>
                    <LineWrapGov>
                        <img src={govLine} alt="" />
                    </LineWrapGov>
                </LineWrapPositionGov>
            </TopWrap>


            <TextWrap>
                <GovTitle>dForce Token</GovTitle>
                <GovDetails>dForce Token (DF) is the utility token that can be staked to govern all dForce products and align interests across different parties within the dForce network, it is the ultimate value-capturer within the expansive dForce ecosystem.</GovDetails>

                <Gap32></Gap32>

                <GovTitle>Vote-Escrowed DF</GovTitle>
                <GovDetails>Vote-Escrowed DF (veDF) represents DF tokens that are locked in the voting escrow contract for a specified period. Users must lock DF tokens to gain access to governance, emission, and airdrops. veDF tokens are not transferable.</GovDetails>

                <Gap32></Gap32>

                <GovTitle>DF Staking</GovTitle>
                <GovDetails>Users can earn emissions by staking their DF tokens in the portal and partake in the shared wealth we create together. Eligible DF stakers will also receive airdrops from the launched dForce AI agents, on top of DF emissions.</GovDetails>
                <StakeBTNwrap>
                    <StakeBTN href='#' target={'_blank'}>
                        Staking Portal
                        <img src={arrowright} alt="" />
                    </StakeBTN>
                    <StakeBTN href='https://gov.dforce.network/#/StakeDF' target={'_blank'}>
                        Old Staking Portal
                        <img src={arrowright} alt="" />
                    </StakeBTN>
                </StakeBTNwrap>
            </TextWrap>


            <Medium>
                <MediumItem href="https://x.com/dForcenet" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logox} alt="" />
                    </MediumItemLogo>
                    <LogoName>X</LogoName>
                </MediumItem>

                <MediumItem href="https://warpcast.com/dforce" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logofar} alt="" />
                    </MediumItemLogo>
                    <LogoName>Farcaster</LogoName>
                </MediumItem>

                <MediumItem href="https://t.me/dforcenet" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logotg} alt="" />
                    </MediumItemLogo>
                    <LogoName>Telegram</LogoName>
                </MediumItem>

                <MediumItem href="https://discord.com/invite/c2PC8SM" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logodis} alt="" />
                    </MediumItemLogo>
                    <LogoName>Discord</LogoName>
                </MediumItem>

                <MediumItem href="https://medium.com/dforcenet" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logomedium} alt="" />
                    </MediumItemLogo>
                    <LogoName>Medium</LogoName>
                </MediumItem>

                <MediumItem href="https://github.com/dforce-network" target={'_blank'}>
                    <MediumItemLogo>
                        <img src={logogit} alt="" />
                    </MediumItemLogo>
                    <LogoName>GitHub</LogoName>
                </MediumItem>
            </Medium>


            <ContactWrap>
                <Contact>
                    <img src={pice} alt="" />
                    <StyledText>Contact: contact@dforce.network</StyledText>
                </Contact>
            </ContactWrap>
        </>
    )
}

export default Page;

const MainColor = styled.span`
    color: #AAF430;
`;


const StakeBTNwrap = styled.div`
    @media (max-width: 1199px) {
        display: flex;
        width: 100%;
        padding: 0 16px;
        margin: 0 auto;
        gap: 16px;
        margin-top: 16px;
    }
    @media (min-width: 1200px) {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        gap: 16px;
        margin-top: 16px;
    }
`;
const StakeBTN = styled.a`
    @media (max-width: 1199px) {
        text-decoration: none;
        border: 1px solid rgba(255, 255, 255, 0.16);
        color: #FFF;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        gap: 8px;
        img {
            width: 10px;
        }
    }
    @media (min-width: 1200px) {
        text-decoration: none;
        border: 1px solid rgba(255, 255, 255, 0.16);
        color: #FFF;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        display: flex;
        align-items: center;
        padding: 16px 24px;
        gap: 8px;
    }
`;
const BackWrap = styled.div`
    @media (max-width: 1199px) {
        border-radius: 4px;
        background: rgba(22, 22, 24, 0.80);
        backdrop-filter: blur(40px);
        padding-bottom: 24px;
    }
`;
const Gap32 = styled.div`
    @media (max-width: 1199px) {
        height: 40px;
    }
    @media (min-width: 1200px) {
        height: 80px;
    }
`;
const H5Title = styled.div`
    @media (max-width: 1199px) {
        padding: 0 16px;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 12px;
        margin-bottom: 8px;
    }
`;
const H5ListWrap = styled.div`
    @media (max-width: 1199px) {
        padding: 0 16px;
    }
`;
const H5List = styled.div`
    @media (max-width: 1199px) {
        border: 1px solid rgba(255, 255, 255, 0.12);
        background: #000;
        display: flex;
        flex-direction: column;
        padding: 8px 0;
    }
`;








const ModalMenu = styled.div`
    @media (max-width: 1199px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        /* background-color: red; */
        background: rgba(0, 0, 0, 0.60);
        z-index: 99;
        /* overflow: hidden; */
        /* touch-action: none; */
    }
    @media (min-width: 1200px) {
        display: none;
    }
`;

const MenuWrapH5 = styled.div`
    @media (max-width: 1199px) {
        padding: 0 16px;
        padding-top: 10px;
        &.reverse {
            flex-direction: row-reverse;
        }
    }
    @media (min-width: 1200px) {
        display: none;
        /* flex-direction: reverse; */
    }
    display: flex;
    justify-content: space-between;
    .logo {
        width: 68px;
        /* height: 30px; */
    }
    .menu {
        width: 26px;
        height: 26px;
    }
`;

const ListWrap = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 2px;
    background: rgba(29, 29, 31, 0.80);
    backdrop-filter: blur(50px);
    position: absolute;
    top: 64px;
    padding: 16px;
    display: none;
`;
const MenuList = styled.a`
    text-decoration: none;
    color: #FFF;
    padding: 0 8px;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    transition: 0.3s;
    line-height: 22px;
    white-space: nowrap;
    @media (min-width: 1200px) {
        font-size: 15px;
        line-height: 34px;
        padding: 0 12px;
        &:hover {
            /* color: #AAF430; */
            /* background-color: red; */
            background: rgba(255, 255, 255, 0.08);
        }
    }
`;

const TopWrap = styled.div`
    width: 100%;
    height: auto;
    background-image: url(${bg});
    background-position: center;
    background-size: 100% 100%;
    /* background-size: contain; */
    background-repeat: no-repeat;
    position: relative;
    @media (min-width: 1200px) {
        width: 100%;
        height: 660px;
        /* height: auto; */
        background-image: url(${bg});
        background-position: center;
        background-size: 100% 100%;
        /* background-size: contain; */
        background-repeat: no-repeat;
        position: relative;
    }
`;
const MenuWrap = styled.div`
    display: none;
    @media (min-width: 1200px) {
        width: 100%;
        height: 70px;
        /* background-color: red; */
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 24px;
    }
`;
const FilterWrap = styled.div`
    width: 100%;
    height: 100px;
    /* background-color: red; */
    background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
    position: absolute;
    bottom: 0;
    @media (min-width: 1200px) {
        width: 100%;
        height: 318px;
        /* background-color: red; */
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        bottom: 0;
    }
`;
const MainTitle = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 55px;
    margin-top: 70px;
    margin-bottom: 12px;
    @media (min-width: 1200px) {
        color: #FFF;
        text-align: center;
        font-size: 104px;
        font-style: normal;
        font-weight: 700;
        line-height: 110px;
        margin-top: 150px;
        margin-bottom: 24px;
    }
`;
const SubTitle = styled.div`
    width: 80%;
    margin: 0 auto;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    @media (min-width: 1200px) {
        width: 800px;
        margin: 0 auto;
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px;
    }
`;
const BTNwrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    @media (min-width: 1200px) {
        margin-top: 60px;
    }
`;
const BTN = styled.div`
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--theme-color, #AAF430);
    color: #000;
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        font-size: 21px;
        padding: 16px 24px;
    }
`;
const BTNdetails = styled.div`
    color: var(--theme-color, #AAF430);
    font-size: 11px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    margin-top: 8px;
    @media (min-width: 1200px) {
        font-size: 21px;
        font-weight: 700;
        margin-top: 16px;
    }
`;
const LineWrapPositionGov = styled.div`
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
`;
const LineWrapGov = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
        img {
            max-width: 100%;
        }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }
`;
const LineWrap = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
    }
`;

const TextWrap = styled.div`
    @media (min-width: 1200px) {
        /* margin-top: -200px; */
    }
`;
const GovTitle = styled.div`
@media (max-width: 1199px) {
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 8px;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        color: #FFF;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 16px;
    }
`;
const GovDetails = styled.div`
@media (max-width: 1199px) {
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
        color: #FFF;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        color: #FFF;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
    }
`;




const LineBox = styled.div`
    width: 50%;
    display: flex;
    img {
        width: 100%;
        margin-left: -22px;
        z-index: 2;
    }
    @media (min-width: 1200px) {
        img {
            margin-left: -30px;
        }
    }
`;
const Box01 = styled.div`
    @media (max-width: 1199px) {
        padding: 0 16px;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
    }
`;
const Box01title01 = styled.div`
    color: rgba(255, 255, 255, 0.40);
    text-align: right;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: rgba(255, 255, 255, 0.40);
        text-align: right;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box01title02 = styled.div`
    color: #FFF;
    text-align: right;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #FFF;
        text-align: right;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box01title03 = styled.div`
    color: #AAF430;
    font-size: 26px;
    text-align: right;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #AAF430;
        text-align: right;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box01Wrap = styled.div`
    @media (min-width: 1200px) {
        display: flex;
        gap: 200px;
        align-items: center;
    }
`;
const Box01left = styled.div`
    img {
        width: 200px;
        height: 200px;
    }
    text-align: center;
    @media (min-width: 1200px) {
        img {
            width: 400px;
            height: 400px;
        }
    }
`;
const Box01right = styled.div`
        display: flex;
        flex-direction: column;
        gap: 16px;
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;
const CTX = styled.div`
    color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    @media (min-width: 1200px) {
        color: #FFF;
        font-size: 21px;
        font-style: normal;
        font-weight: 400;
        line-height: 34px;
    }
`;
const LineLeftWrap = styled.div`
    width: 100%;
    margin: 0 auto;
    img {
        width: 46%;
        /* height: 249px; */
        margin-left: 18px;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        img {
            width: 574px;
            height: 249px;
            margin-left: 2px;
        }
    }
`;
const Box02 = styled.div`
    @media (max-width: 1199px) {
        width: 100%;
        padding: 0 16px;
        margin: 0 auto;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
    }
`;
const Box02title01 = styled.div`
    color: rgba(255, 255, 255, 0.40);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: rgba(255, 255, 255, 0.40);
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box02title02 = styled.div`
    color: #FFF;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #FFF;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box02title03 = styled.div`
    color: var(--theme-color, #AAF430);
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #AAF430;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;
const Box02Wrap = styled.div`
    display: flex;
    flex-direction: column-reverse;
    @media (min-width: 1200px) {
        display: flex;
        gap: 200px;
        align-items: center;
        flex-direction: row;
    }
`;
const Box02left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (min-width: 1200px) {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
`;
const Box02right = styled.div`
    text-align: center;
    img {
        width: 200px;
        height: 200px;
    }
    @media (min-width: 1200px) {
        img {
            width: 400px;
            height: 400px;
        }
    }
`;
const AIAgentsTiele = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 50px;
    margin-bottom: 40px;
    @media (min-width: 1200px) {
        color: #FFF;
        text-align: center;
        font-size: 51px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 100px;
        margin-bottom: 80px;
    }
`;
const AIAgentsWrap = styled.div`
    @media (max-width: 1199px) {
        width: 100%;
        margin: 0 auto;
        padding: 0 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
    }
`;
const AIAgentsItem = styled.div`
    width: calc(50% - 12px);
    background: #161618;
    display: flex;
    padding: 12px 0;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    img {
        width: 36px;
        height: 36px;
    }
    @media (min-width: 1200px) {
        width: 282px;
        background: #161618;
        padding: 24px 0;
        display: flex;
        flex-direction: column;
        gap: 16px;
        align-items: center;
        img {
            width: 60px;
            height: 60px;
        }
    }
`;
const AIAgentsItemTiele = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #FFF;
        text-align: center;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
const Gif3Wrap = styled.div`
    margin-top: 50px;
    text-align: center;
    img {
        width: 200px;
        height: 200px;
    }
    @media (min-width: 1200px) {
        margin-top: 80px;
        text-align: center;
        img {
            width: 400px;
            height: 400px;
        }
    }
`;
const Gif3Text = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        text-align: center;
        color: #FFF;
        text-align: center;
        font-size: 38px;
        font-style: normal;
        font-weight: 700;
        line-height: 52px;
    }
`;
const Medium = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    @media (min-width: 1200px) {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        margin-top: 80px;
    }
`;
const MediumItem = styled.a`
    width: 33%;
    text-decoration: none;
    padding: 12px 0;
    gap: 8px;
    @media (min-width: 1200px) {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 24px 0;
        text-decoration: none;
    }
`;
const MediumItemLogo = styled.div`
    text-align: center;
    img {
        width: 30px;
        height: 30px;
    }
    @media (min-width: 1200px) {
        text-align: center;
        img {
            width: 60px;
            height: 60px;
        }
    }
`;
const LogoName = styled.div`
    color: #EEE;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    @media (min-width: 1200px) {
        color: #EEE;
        text-align: center;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
`;
const ContactWrap = styled.div`
        margin-top: 16px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
    @media (min-width: 1200px) {
        margin-top: 32px;
        margin-bottom: 80px;
        display: flex;
        justify-content: center;
    }
`;
const Contact = styled.div`
    border: 1px solid #FFF;
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    img {
        width: 14px;
    }
    @media (min-width: 1200px) {
        border: 1px solid #FFF;
        display: flex;
        padding: 16px 24px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        img {
            width: 24px;
        }
    }
`;
const StyledText = styled.div`
    color: #FFF;
    text-align: center;
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    @media (min-width: 1200px) {
        color: #FFF;
        text-align: center;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: 17px;
    }
`;









const MenuLeft = styled.div`
    img {
        width: 110px;
        height: 24px;
    }
`;
const MenuRight = styled.div`
    display: flex;
    gap: 40px;
    height: 100%;
`;
const MenuItem = styled.div`
    height: 100%;
    /* background-color: green; */
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #FFF;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    position: relative;
    &:hover {
        ${ListWrap} {
            display: flex;
        }
    }
`;
